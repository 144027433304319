// Imports
import BaseAdvertisementVideoPlayer from './BaseAdvertisementVideoPlayer.vue'
import BaseAnimatedCounter from './BaseAnimatedCounter.vue'
import BaseAvatar from './BaseAvatar'
import BaseBadge from './BaseBadge'
import BaseButton from './BaseButton'
import BaseCalendar from './BaseCalendar'
import BaseCard from './BaseCard'
import BaseCardTransition from './BaseCardTransition'
import BaseCardTransitionGroup from './BaseCardTransitionGroup.vue'
import BaseCarousel from './BaseCarousel'
import BaseCheckbox from './BaseCheckbox'
import BaseColorTag from './BaseColorTag'
import BaseContentEditable from './BaseContentEditable'
import BaseDrawer from './BaseDrawer'
import BaseDropdownActions from './BaseDropdownActions'
import BaseDynamicLoader from './BaseDynamicLoader.vue'
import BaseFilterSelect from './BaseFilterSelect'
import BaseInput from './BaseInput'
import BaseInputV2 from './BaseInputV2'
import BaseLoadingLogo from './BaseLoadingLogo'
import BaseLoadingSpinner from './BaseLoadingSpinner'
import BaseLoadingSpinnerCircle from './BaseLoadingSpinnerCircle.vue'
import BaseProgressIndicatorRing from './BaseProgressIndicatorRing.vue'
import BaseModal from './BaseModal'
import BaseSelect from './BaseSelect'
import BaseSingleSelect from './BaseSingleSelect'
import BaseSplitSelect from './BaseSplitSelect'
import BaseTable from './BaseTable'
import BaseTabs from './BaseTabs'
import BaseText from './BaseText'
import BaseToggle from './BaseToggle'
import BaseTooltip from './BaseTooltip'
import BaseViewToggle from './BaseViewToggle'
import BasePopupVideoPreview from './BasePopupVideoPreview.vue'
import BasePopupThumbnailPreview from './BasePopupThumbnailPreview.vue'
import BaseColorPicker from './BaseColorPicker.vue'
import BaseCreateNewDropdown from './BaseCreateNewDropdown.vue'
import BaseTutorialModal from './BaseTutorialModal.vue'

export default {
  BaseAdvertisementVideoPlayer,
  BaseAnimatedCounter,
  BaseAvatar,
  BaseBadge,
  BaseButton,
  BaseCalendar,
  BaseCard,
  BaseCardTransition,
  BaseCardTransitionGroup,
  BaseCarousel,
  BaseCheckbox,
  BaseColorTag,
  BaseContentEditable,
  BaseDrawer,
  BaseDropdownActions,
  BaseDynamicLoader,
  BaseFilterSelect,
  BaseInput,
  BaseInputV2,
  BaseLoadingLogo,
  BaseLoadingSpinner,
  BaseLoadingSpinnerCircle,
  BaseProgressIndicatorRing,
  BaseModal,
  BaseSelect,
  BaseSingleSelect,
  BaseSplitSelect,
  BaseTable,
  BaseTabs,
  BaseText,
  BaseToggle,
  BaseTooltip,
  BaseViewToggle,
  BasePopupVideoPreview,
  BasePopupThumbnailPreview,
  BaseColorPicker,
  BaseCreateNewDropdown,
  BaseTutorialModal
}
