// Imports
import AxiosConfig from './serverAxiosConfig'
import firebase from '../config/FirebaseConfig'
import { createQuery } from '../../utils/filterUtils'

// Fetch AD
const getBoardAds = async (boardId, nextId, filters) => {
  // Pull the current token off the user
  const { Aa: token } = firebase.auth().currentUser

  const params = {
    // Create the filters
    ...createQuery(filters),
    next: nextId
  }

  const { data } = await AxiosConfig.get(
    `/ads/board/${boardId}`,
    {
      params,
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )

  return data
}

const getBoardsAdCount = async (teamId) => {
  const user = firebase.auth().currentUser;

  // If no user, don't query for ad count of a board
  if (!user){
    return;
  }
  const token = user.Aa;

  const payload = {}
  if (teamId) {
    payload.teamId = teamId
  }

  const { data } = await AxiosConfig.post(
    '/ads/board/count',
    payload,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )
  return data.count ? data.count : 0
}

const getSharedBoardsAdCount = async (boardId) => {
  const { data } = await AxiosConfig.post(
    '/ads/share/board/count',
    {boardId}
  )
  return data.count ? data.count : 0
}

const getPublicBoardAds = async (boardId, nextId, filters) => {
  const params = {
    // Create the filters
    ...createQuery(filters),
    next: nextId
  }

  const { data } = await AxiosConfig.get(
    `/ads/shared/board/${boardId}`,
    {
      params
    }
  )

  return data
}

const getLibraryAds = async (nextId, filters) => {
  // Pull the current token off the user
  const { Aa: token } = firebase.auth().currentUser

  const params = {
    // Create the filters
    ...createQuery(filters),
    next: nextId
  }

  const { data } = await AxiosConfig.get(
    '/ads/library',
    {
      params,
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )
  return data
}

const getDiscoveryAds = async (nextId, filters, includeSpyder = false) => {
  // Pull the current token off the user
  const { Aa: token } = firebase.auth().currentUser

  const params = includeSpyder 
    ? { ...createQuery(filters), spyder: true, next: nextId }
    : { ...createQuery(filters), next: nextId }

  const { data } = await AxiosConfig.get(
    '/ads/discovery',
    {
      params,
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )

  return data
}

const getShuffledDiscoveryAds = async (nextId, filters) => {
  // Pull the current token off the user
  const { Aa: token } = firebase.auth().currentUser

  const params = {
    // Create the filters
    ...createQuery(filters),
    next: nextId
  }

  const { data } = await AxiosConfig.get(
    '/ads/shuffledDiscovery',
    {
      params,
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )

  return data
}

const getSpyderAds = async (nextId, filters) => {
  // Pull the current token off the user
  const { Aa: token } = firebase.auth().currentUser

  const params = {
    // Create the filters
    ...createQuery(filters),
    spyder: true,
    next: nextId
  }

  const { data } = await AxiosConfig.get(
    '/ads/discovery',
    {
      params,
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )

  return data
}

const setSpyderAdDiscoverable = async (adId) => {
  // Pull the current token off the user
  const { Aa: token } = firebase.auth().currentUser

  const { data } = await AxiosConfig.post(
    '/ads/set-discoverable',
    {
      adId
    },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )

  return data
}

const getExpertAds = async (expertId, nextId, filters) => {
  const params = {
    // Create the filters
    ...createQuery(filters),
    next: nextId
  }

  const { data } = await AxiosConfig.get(
    `/ads/expert/${expertId}`,
    {
      params
    }
  )

  return data
}

const createTranscriptionJob = async (adId, manualUpload = false) => {
  // Pull the current token off the user
  const { Aa: token } = firebase.auth().currentUser

  const { data } = await AxiosConfig.post(
    '/transcriptions/ads',
    {
      adId,
      manualUpload
    },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )

  return data
}

const getTagAdCount = async (tagId) => {
  // Pull the current token off the user
  const { Aa: token } = firebase.auth().currentUser

  const { data } = await AxiosConfig.get(
    `/ads/tags/${tagId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )

  return data.count ? data.count : 0
}

const createBoardExportJob = async (boardId) => {
  // Pull the current token off the user
  const { Aa: token } = firebase.auth().currentUser

  const { data, status } = await AxiosConfig.post(
    `/ads/board/${boardId}/export`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )

  return { data, status }
}

const getBoardExportStatus = async (boardId) => {
  // Pull the current token off the user
  const { Aa: token } = firebase.auth().currentUser

  const { data, status } = await AxiosConfig.get(
    `/ads/board/${boardId}/export`,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )

  return { data, status }
}

const getSimilarAds = async (adId) => {
  // Pull the current token off the user
  const { Aa: token } = firebase.auth().currentUser

  const { data } = await AxiosConfig.get(
    '/ads/suggestions',
    {
      params: {
        id: adId
      },
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )

  return data
}

const getSimilarCategories = async (type, categoryName) => {
  const validRouteTypes = ['product-category', 'creative-targeting']
  if (!validRouteTypes.includes(type)) {
    throw new Error(`Invalid type: ${type}, must be one of ${validRouteTypes.join(', ')}`)
  }

  // Pull the current token off the user
  const { Aa: token } = firebase.auth().currentUser

  const queryParams = type === 'product-category' ? { productCategory: categoryName } : { creativeTargeting: categoryName }

  const { data } = await AxiosConfig.get(
    `ads/${type}/suggestions`,
    {
      params: queryParams,
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )

  return data
}

export default {
  getBoardAds,
  getBoardsAdCount,
  getDiscoveryAds,
  getExpertAds,
  getLibraryAds,
  getPublicBoardAds,
  createTranscriptionJob,
  getSpyderAds,
  setSpyderAdDiscoverable,
  getTagAdCount,
  getShuffledDiscoveryAds,
  createBoardExportJob,
  getBoardExportStatus,
  getSharedBoardsAdCount,
  getSimilarAds,
  getSimilarCategories
}
